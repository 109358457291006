import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import {AppBar, Box,Container,Button,  Menu , MenuItem, Toolbar,Typography,  Link} from '@mui/material';

const pages = [{label:'О нас ', link: '#about'}, {label:'Цены', link: '#price'}, {label:'Команда', link: '#masters'}, {label:'Мы находимся', link: '#local'}];


const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div>
            <div >
        <AppBar position="fixed" >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ my:5, display: { xs: 'flex', md: 'none' }}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                            display: { xs: 'block', md: 'none' },
                            }}>
                            {pages.map((page) => (
                                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                                    <Link underline="none" sx={{ color: 'black'}} href={page.link}>
                                        <Typography textAlign="center" sx={{textTransform: 'uppercase'}}>
                                            {page.label}
                                        </Typography>
                                    </Link>    
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }}} >
                        {pages.map((page) => (
                        <Link
                            
                            href={page.link}
                            key={page.label}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 5, color: 'black', display: 'block', cursor: 'pointer' }}>
                                <Typography textAlign="center" sx={{mx: 1, fontWeight: '700', textTransform: 'uppercase'}}>
                                            {page.label}
                                </Typography>
                            
                        </Link>
                        ))}
                    </Box>
                    <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column',  justifyContent: 'center'}}>
                        <Box sx={{
                                transform: 'translateX(-50%)',
                                top: 0,
                                left: '50%',
                                position: 'absolute'}}>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                        console.info("I'm a button.");
                                        }}>
                                        <div>
                                            <img src="img/logo12.png" alt="" />
                                        </div>
                                    </Link>
                                    <Typography
                                        textAlign="center"
                                        sx={{
                                            marginTop: '-20px',
                                            color: 'black',     
                                        fontWeight: '500',
                                        fontSize: '0.875rem',
                                        lineHeight: '1.75',
                                        letterSpacing: '0.02857em',
                                        textTransform: 'uppercase'}}>
                                        Парикмахерская
                                    </Typography>
                        </Box>
                    </Box>
                </Toolbar>
        </Container>
    </AppBar>
    </div>
    </div>
    );
};
export default Header;
