import "./masters.scss";

const Masters = () => {
    return (
        <div>
            <div className="container">
                <section className="masters" id="masters">
            <h2 className="title title_fz36 masters__title"> Наша команда </h2>
            <div className="divider"></div>
            <div className="masters__items masters__container">
                <div className="masters__item">
                    <div className="masters__item-img">
                        <img src="img/_DSC0981.jpg" width={300} alt="masters img" />
                    </div>
                    <div className="masters__item-name title_fz16">Оксана</div>
                    <div className="masters__item-job">Мужской мастер</div>
                </div>
                <div className="masters__item">
                    <div className="masters__item-img">
                            <img src="img/_DSC1084.jpg" width={300} alt="ggg" />
                    </div>
                    <div className="masters__item-name title_fz16">Вика</div>
                    <div className="masters__item-job">Мастер универсал</div>
                </div>
                <div className="masters__item">
                    <div className="masters__item-img">
                        <img src="img/_DSC1022.jpg" width={300} alt="ggg" />
                    </div>
                    <div className="masters__item-name title_fz16">Лена</div>
                    <div className="masters__item-job">Маникюрный мастер</div>
                </div>
                <div className="masters__item">
                    <div className="masters__item-img">
                        <img src="img/_DSC1082.jpg" width={300} alt="ggg" />
                    </div>
                    <div className="masters__item-name title_fz16">Лера</div>
                    <div className="masters__item-job">Мастер универсал</div>
                </div>
                <div className="masters__item">
                <div className="masters__item-img">
                        <img src="img/_DSC1144.jpg" width={300} alt="ggg" />
                    </div>
                        <div className="masters__item-name title_fz16">Оксана</div>
                        <div className="masters__item-job">Мастер универсал</div>
                </div>
                <div className="masters__item">
                    <div className="masters__item-img">
                        <img src="img/_DSC1083.jpg" width={300} alt="ggg" />
                    </div>
                    <div className="masters__item-name title_fz16">Настя</div>
                    <div className="masters__item-job">Мужской мастер</div>
                </div>
            </div>
            </section>
            </div>
        </div>
    )

}

export default Masters;