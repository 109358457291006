
import './price.scss';

const Price = () => {
    return (
    <div>
        <div className="container" id="price">
            <section className="price">
                <div className="price__wrapper">
                    <h2 className="title title_fz36 price__title">Прайс-лист</h2>
                    <div className="divider"></div>
                    <div className="price__container">
                        <h4 className="title title_fz16 text_apper">Стрижки мужские </h4>
                        <div className="price__text">Мы считаем, что идеальная стрижка смотрится одинаково хорошо, как на выходе из парикмахерской, так и спустя несколько недель у вас дома.</div>
                        <div className="price__service-wrapper">
                            <div className="price__colom-first">
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Cтрижка простая</div>
                                        <div className="price__count"> 350 руб.</div>
                                    </div> 
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ... 
                                        </div>
                                </div> 
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Cтрижка "Бокс"</div>
                                        <div className="price__count"> 510 руб.</div>
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ... 
                                        </div>
                                </div> 
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Cтрижка классическая</div>
                                        <div className="price__count"> 710 руб.</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ...
                                    </div>
                                </div>
                                
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Cтрижка креативная</div>
                                        <div className="price__count"> от 750 руб.</div>
                                    </div> 
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ... 
                                        </div>
                                </div> 
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Стрижка детская </div>
                                        <div className="price__count"> 450/500/600 руб.*</div>
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ... 
                                        </div>
                                        <div className="price__note">
                                                * Стоимость услуги зависит от возраста (до 7 лет/ до 13 лет/ от 14 до 17 лет)
                                        </div>
                                        
                                </div> 
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Стрижка для неработающих пенсионеров "Социальная" </div>
                                        <div className="price__count"> 400 руб.</div>
                                    </div> 
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ... 
                                        </div>
                                </div>    
                            </div>
                            <div className="price__colom-second">
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Коррекция стрижки</div>
                                        <div className="price__count"> 500 руб.</div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="price__descr">
                                        Услуга, ... 
                                    </div>
                                </div>
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Стрижка бороды и усов</div>
                                        <div className="price__count"> 300 руб.</div>
                                    </div> 
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, 
                                        </div>
                                </div>
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Массаж головы</div>
                                        <div className="price__count"> 200 руб.</div>
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ...
                                        </div>
                                </div>
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Мытье головы</div>
                                        <div className="price__count"> 100 руб.</div>
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ...
                                        </div>
                                </div>
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Надбавка за HAIR TATTOO</div>
                                        <div className="price__count"> 100 руб.</div>
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, ...
                                        </div>
                                </div>
                                <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Надбавка за переход с нуля или 2 мм</div>
                                        <div className="price__count"> 100 руб.</div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="price__descr">
                                            Услуга, ...
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  className="price__container">
                            <h4 className="title title_fz16 text_apper">Стрижки женские</h4>
                            <div className="price__text"> Подберем для вас стрижку с учетом структуры ваших волос, пропорций лица и привичку делать или не делать укладку </div>
                                <div className ="price__service-wrapper">
                                    <div className="price__colom-first">
                                        <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Женская стрижка с укладкой</div>
                                            <div className="price__count"> 1000/1200/1500 руб.*</div>
                                        </div>    
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                В стоимость услуги входит мытье головы с уходовым бальзамом, стрижка, укладка феном с уходовым или укадочным продуктом 
                                            </div>
                                            <div className="price__note">
                                                * Стоимость услуги зависит от длины волос (короткая, полудлинная и длинная)
                                            </div>
                                        </div>
                                        <div className="price__item">
                                            <div className="price__box">
                                                <div className="price__type">Стрижка челки</div>
                                                <div className="price__count"> 250/300 руб. *</div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                Услуга ...
                                            </div>
                                            <div className="price__note">
                                                * Стоимость услуги зависит от вашей привычки уклыдывать челку (фен, утяжок)
                                            </div>
                                        </div>
                                        <div className="price__item">
                                            <div className="price__box">
                                                <div className="price__type">Стрижка детская до 9 лет</div>
                                                <div className="price__count"> 500 руб.</div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                Услуга, ... 
                                            </div>
                                        </div> 
                                        <div className="price__item">
                                            <div className="price__box">
                                                <div className="price__type">Стрижка для неработающих пенсионеров "Социальная" </div>
                                                <div className="price__count"> 500 руб.</div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                Услуга, ... 
                                            </div>
                                        </div> 
                                        
                                    </div>
                                    <div className="price__colom-second">
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Мытье волос </div>
                                            <div className="price__count"> 200/300/400 руб.</div>
                                        </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                Услуга, включает уходовый шампюнь и бальзам 
                                            </div>
                                            <div className="price__note">
                                                * Стоимость услуги зависит от длины волос (короткая, полудлинная и длинная)
                                            </div>
                                    </div> 
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Укладочное средство</div>
                                            <div className="price__count"> 100/150/200 руб.</div>
                                        </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                                Услуга, ... 
                                            </div>
                                            <div className="price__note">
                                                * Стоимость услуги зависит от длины волос (короткая, полудлинная и длинная)
                                            </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Восстанавливающий уход Keune </div>
                                            <div className="price__count"> 500 руб.</div>
                                        </div>
                                            <div className="divider"></div>
                                            <div className="price__descr">
                                            Когда привычная маска с полочки в ванной бессильна, за восстановление здоровья ваших волос берутся наши уходы. 
                                            </div>
                                    </div> 

                                    </div>
                                </div>
                        </div>

                        <div className="price__container"> 
                            <h4 className="title title_fz16 text_apper">Окрашивание волос </h4>
                            <div className="price__text">Для окрашивания волос мы используем красители Keune. Итоговая стоимость услуги зависит от расхода выбранного красителя.</div>
                            <div className="price__service-wrapper">
                                <div className="price__colom-first">
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Окрашивание в один тон Вашей краской</div>
                                            <div className="price__count"> 600/900/1500 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__note">
                                            *  Стоимость услуги зависит от длины волос (короткая, полудлинная и длинная)
                                        </div>
                                    </div> 
                                    <div className="price__item">
                                        <div className="price__box">
                                        <div className="price__type">Окрашивание в один тон краской Keune</div>
                                        <div className="price__count"> от 2500 руб. *</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Стойкое окрашивание, позволяющее закрасить седину и сохранить насыщенный цвет волос на долгое время
                                        </div>
                                        <div className="price__note">
                                            * Стоимость зависит от густоты и длины волос
                                        </div>
                                    </div> 
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Окрашивание TOTAL BLOND</div>
                                            <div className="price__count"> от 2000 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            То самое окрашивание, если вам хочется стать блондинкой от самых корней и до кончиков волос.
                                        </div>
                                        <div className="price__note">
                                            * Процедура занимает от 3 до 6 часов, стоимость зависит от густоты и длины волос. Требуется предварительная консультация
                                        </div>
                                    </div> 
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Коррекция окрашивания TOTAL BLOND</div>
                                            <div className="price__count"> от 1300 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Если какое-то время назад вы уже стали блондинкой и пришло время скорректировать цвет корней.
                                        </div>
                                        <div className="price__note">
                                            * Процедура занимает от 2 до 3 часов, стоимость зависит от густоты и длины волос. Требуется предварительная консультация
                                        </div>
                                    </div> 
                                    
                                    
                                </div>
                                <div className="price__colom-second">
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Окрашивание с осветлением</div>
                                            <div className="price__count"> от 1300 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Различные техники осветления, имитирующие частичное выгорание волос на солнце: омбре, шатуш, мелирование, highlights
                                        </div>
                                        <div className="price__note">
                                            * Процедура занимает от 2 до 5 часов, стоимость зависит от густоты и длины волос. Требуется предварительная консультация
                                        </div>
                                    </div> 
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Окрашивание по технике AIRTOUCH</div>
                                            <div className="price__count"> от 5000 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Инновационная техника осветления, которая позволяет сделать деликатное осветление, словно вы только что из отпуска, или плавный переход из надоевшего блонда в ваш натуральный цвет. А еще это лучший способ исправления предыдущего неудачного окрашивания.
                                        </div>
                                        <div className="price__note">
                                            * Процедура занимает от 5 до 6 часов, стоимость зависит от густоты и длины волос. Требуется предварительная консультация
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="price__container"> 
                            <h4 className="title title_fz16 text_apper">Укладка волос</h4>
                            <div className="price__text">Укладка волос представляет собой обязательный ежедневный ритуал большинства современных женщин. Многие укладывают волосы самостоятельно, и у них это получается великолепно. Однако, профессиональную укладку волос в салоне не сможет заменить использование обычного фена или утюжка в домашних условиях.</div>
                            <div className="price__service-wrapper">
                                <div className="price__colom-first">
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">В стиле CASUAL</div>
                                            <div className="price__count"> 200/300/400 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Сушка феном
                                        </div>
                                        <div className="price__note">
                                            *   Стоимость услуги зависит от длины волос (короткая, полудлинная и длинная)
                                        </div>
                                    </div> 
                                    
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Укладка волны</div>
                                            <div className="price__count"> от 1000 и выше руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Создатим локаны утяжком или плойкой, а может вы захотите гофре 
                                        </div>
                                        <div className="price__note">
                                            *   Стоимость услуги зависит от длины (короткая, полудлинная и длинная) волос  и густоты
                                        </div>
                                    </div> 
                                </div>
                                <div className="price__colom-second">
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Укладка вечерняя</div>
                                            <div className="price__count"> Индивидуально*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга
                                        </div>
                                        <div className="price__note">
                                            *   Стоимость услуги зависит от длины (короткая, полудлинная и длинная) волос и их густоты, а также типа стрижки. . Требуется предварительная консультация
                                        </div>
                                    </div>  
                                </div>

                            </div>
                        </div>
                        <div className="price__container">
                            <h4 className="title title_fz16 text_apper">Маникюр и Педикюр</h4>
                            <div className="price__text"> Зал манюкюра устроен по принципу: мы очень любим безопастность, поэтому стерилизация маникюрно-педикюрных инструментов в парикмахерской включает в себя 4 этапа: замачивание в ванну с обеззараживающим раствором, механическая отчистка, стерилизация в сухожаровом шкафу и хранение в специальных крафт-пакетах. Кроме того, для покрытия ногтей используются безопастные марки CND, LUXIO, не содержащих в своем составе вредных веществ </div>       
                            <div className="price__service-wrapper">
                                <div className="price__colom-first">
                                    <div className="price__item">
                                        <div className="price__box">
                                        <div className="price__type">Маникюр</div>
                                        <div className="price__count">женский 700 руб./мужской 800 руб.</div>
                                        
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Наш знаковый классический маникюр с обрезкой кутикулы щипчиками и с помощью аппарата-фреза. Услуга делает без покрятия лаком
                                        </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Покрытие ногтей гель-лаком на руках</div>
                                            <div className="price__count"> 1000 руб.</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, позволяющая продлить свежесть маникюра, включает в себя выравнивание ногтевой пластины, покрытие гель-лаком 
                                        </div>
                                    </div>
                                    
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Моделирование ногтей </div>
                                            <div className="price__count"> 2200/2500 руб.*</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга позволяет скорректировать тонкую, ломкую ногтевую пластину, непропорциональную форму, а также сделать эффективный дизайн, в том числе объемный
                                        </div>
                                        <div className="price__note">
                                            *   Стоимость услуги зависит от материала (гель/акрил)
                                        </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Дизайн</div>
                                            <div className="price__count">Индивидуально</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Создам дизайн ваших ногтей используя блестки, фольгу, песок, втирку или нарисуем...
                                        </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Парафиновая ванна для рук</div>
                                            <div className="price__count">300 руб.</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Данная процедура оказывает болеутоляющее и освежающее действие на кисти рук, омолажевает и питаниет кожу, выводит токсины и лишнею влагу, насыщяет более глубокие слоя кожи полезными веществами. Особенно актуальной данная процедура становится в холодное время года, когда кожа рук наиболее сильно нуждается в уходе
                                        </div>
                                    </div>
                                </div>
                                <div className="price__colom-second">
                                    <div className="price__item">
                                    <div className="price__box">
                                        <div className="price__type">Педикюр</div>
                                        <div className="price__count">женский 1800 руб./мужской 2200 руб.</div>
                                        
                                    </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Наш знаковый классический педикюр с обрезкой кутикулы щипчиками и с помощью аппарата-фреза, обработкой пяток пилкой. Услуга делает без покрятия лаком
                                        </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Педикюр Мини</div>
                                            <div className="price__count">900 руб.</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга включает только обрезку кутикулы щипчиками и с помощью аппарата-фреза
                                        </div>
                                    </div>
                                    <div className="price__item">
                                        <div className="price__box">
                                            <div className="price__type">Покрытие ногтей гель-лаком на ногах</div>
                                            <div className="price__count">1000 руб.</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="price__descr">
                                            Услуга, позволяющая продлить свежесть педикюра, включает в себя выравнивание ногтевой пластины, покрытие гель-лаком 
                                        </div>
                                    </div>
                                </div>

                            </div>           
                        </div>

                        <div  className="price__container">
                            <h4 className="title title_fz16 text_apper">Брови и ресницы</h4>
                            <div className="price__text"> Можно долго завидовать бровям Кары Делевинь и не спать ночами, а можно прийти к нам и подобрать форму и оттенок подходящий именно к вашему лицу. А если вам надоело каждый день красить ресницы — мы с удовольствием избавим вас от этой рутинной привычки </div>
                            <div className ="price__service-wrapper">
                                <div className="price__colom-first">
                                        <div className="price__item">
                                            <div className="price__box">
                                                <div className="price__type">Коррекция и окращивание бровей</div>
                                                <div className="price__count">350 руб.</div>
                                            </div>
                                            <div className="divider"></div>   
                                        </div>
                                </div>
                                <div className="price__colom-second">
                                        <div className="price__item">
                                            <div className="price__box">
                                                <div className="price__type">Окращивание ресниц</div>
                                                <div className="price__count">100 руб.</div>
                                            </div>
                                            <div className="divider"></div>    
                                        </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </section>
        </div>
    </div>
    )
} 
export default Price;