import React from 'react';
import ReactDOM from 'react-dom/client';

import { ThemeProvider, createTheme} from '@mui/material';

import './index.scss';
import "./sass/base/media.scss";


import App from './components/App/App';

const theme = createTheme({
  palette: {
      primary: {
        main: "#fff"
      },
    secondary: {
      main: '#d29634'
    }}

});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);


