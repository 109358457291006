
import './local.scss';



const Local = () => {
    return (
        <div>
            <div className="container">
                <section className="local" id="local">
                    <h2 className="title title_fz36 local__title"> Контакты</h2>
                    <div className="divider"></div>
                    <div className="local__container">
                        <div className="local__contacts">
                            <b className="contacts__title">Парикмахерская &#171;Шарм&#187; </b>
                            <p className="contacts__text">
                                г. Советская Гавань,  Улица 15 Погибших Партизан, 81 <br/>
                                <a class="contacts__phone" href="tel:+74213845565">
                                    <span class="contacts__phone-mobile"> Тел.:</span>  +7 (963) 826-55-65
                                </a>
                            </p>
                            <div className="social">
                            <b className="social__title">Давай дружить: </b>
                                <a className="social__link" href="https://www.instagram.com/sharm_svg/">мы в Инстаграме</a>
                        </div>
                        <div className="copyright">
                            <b className="copyright__text">Разработано: </b>
                            <a class="copyright__link" href="http://alisawebdev.com/">alisawebdev</a>
                            
                        </div>
                        </div> 
                        <div className="local__maps">
                            <div className="maps-size">
                                <iframe width="657" height="429" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=657&amp;height=429&amp;hl=en&amp;q=%D0%A3%D0%BB%D0%B8%D1%86%D0%B0%2015%20%D0%9F%D0%BE%D0%B3%D0%B8%D0%B1%D1%89%D0%B8%D1%85%20%D0%9F%D0%B0%D1%80%D1%82%D0%B8%D0%B7%D0%B0%D0%BD,%2081%20%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%B3%D0%B0%D0%B2%D0%B0%D0%BD%D1%8C+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
                            </div>                        
                        </div>
                    </div>
        </section>
        </div>
        </div>
    )
}
export default Local;