import Header from '../Header/Header';
import About from '../About/About';
import Masters from '../Masters/Masters';
import Price from '../Price/Price';
import Local from '../Local/Local';

const App = () => {
  return (
    <div>
        <Header />
        <About/>
        <Price/>
        <Masters/>
        <Local/> 
    </div>
  )
}

export default App;
