
import './about.scss';

const About = () => {
    return (
        <div className="container">
            <section className="about" id="about">
            <h2 className="title title_fz36 about__title none"> О парикмахерской</h2>
            <div className="divider none"></div>
                    <div className="about__row">
                        <div className="about__col_first">
                            <div className="about__img_first">
                                <img  src="img/_DSC0878.jpg" width={350} alt="about_photo"/>
                            </div>
                            <div className="about__img_third">
                                <img  src="img/DSC_5964b3.jpg"  width={350} alt="about_photo"/>
                            </div>
                            
                        </div>
                        
                        <div className="about__col_second">
                            <div className="about__img_fourth">
                                <img  src="img/_DSC1201.jpg"  width={350} alt="about_photo"/>
                            </div>
                            <div className="about__img_second">
                                <img  src="img/_DSC1034.jpg" width={350} alt="about_photo"/>
                            </div>
                            
                        </div>
                        <div className="about__col_third">
                            <h2 className="title title_fz36 about__title about__title-none"> О парикмахерской</h2>
                            <div className="divider about__title-none"></div>
                            <div className="about__txt">
                                <p>Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley of type
                                    and scrambled it to make a type specimen book. It has survived not only
                                    five centuries, but also the leap into electronic typesetting, remaining essentially
                                    unchanged.</p>
                            </div>
                        </div>
                    </div>
            </section>
        </div>
    )

}
export default About;